<template>
  <div class="home">
    <menuTemplate :screens="screens" :menu="menu" :playerInstance="playerInstance"></menuTemplate>
    <router-view :playerInstance="playerInstance"></router-view>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { eventBus } from "@/eventBus";

export default {
  props: {
    menuDetail: {
      type: Object,
    },
    playerInstance: {
      type: Object,
    },
  },
  data() {
    return {
      menu: null,
      screens: null,
    };
  },
  computed: {
    ...mapGetters(["appConfig"]),
  },
  watch: {
    menuDetail(val) {
      if (val) {
        this.loadTemplate(val);
      }
    },
  },
  created() {
    // console.log("menu", this.menu);
    this.loadInitialTemplate();
  },
  mounted() {
    // this.loadRoutedTemplate();
    // eventBus.$on("seeAll-from-home", this.loadSeeAllTemplate);
  },
  methods: {
    // function to load the template on initial app load.
    loadRoutedTemplate() {
      console.log("ROUTER PARAM LINK --- HOME", this.$route.params.link);
      if (this.$route.params.link) {
        this.loadTemplate(this.$route.params.link);
        this.menu = this.$route.params.link;
      }
    },

    //load initial Template i.e home
    loadInitialTemplate() {
      if (!this.appConfig) {
        this.loadTemplate(null);
      } else {
        // this.loadTemplate(this.appConfig.menu[0]);
        let profileDetails = localStorage.getItem("subscriberProfileDetails");
        if (profileDetails) {
          profileDetails = JSON.parse(profileDetails);
        }
        if (profileDetails && profileDetails.kidsmode === "YES") {
          this.appConfig.moreMenuOptions.forEach((menu) => {
            if (menu.profileTypes && menu.profileTypes.includes("KIDS")) {
              this.loadTemplate(menu);
            }
          });
        } else {
          let menuTemp = null;

          this.appConfig.moreMenuOptions.forEach((menu) => {
            if (menu.id === "SONGS") {
              menuTemp = menu;
            }
          });
          
          this.loadTemplate(menuTemp);

        }
      }
    },

    //function to load the home template screen with provided menu screens.
    loadTemplate(menu) {
      console.log("THE MENU I AM GETTING -> ",menu)
      if (menu) {
        this.menu = menu;

        let index = this.appConfig.screens.findIndex((element) => {
          return this.menu.id === element.id;
        });

        if (index > -1) {
          this.screens = this.appConfig.screens[index];
        }
      }
    },
  },
  components: {
    menuTemplate: () => import(/* webpackChunkName: "menuTemplate" */ "@/components/Templates/menuTemplate.vue"),
  },
};
</script>

<style lang="scss"></style>
